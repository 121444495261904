import React, { useState, useEffect } from 'react';
import './CertList.scss';
import { Table, Button } from 'react-bootstrap';
import { apiUrl, hubUrl } from '../Api';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

function CertList(props) {
    const [ hubConnection, setHubConnection] = useState(undefined);
    const [ clientsConnected, setClientsConnected ] = useState({});

    const { certificates } = props;

    useEffect(() => {
        // Based on https://www.sammeechward.com/signal-r-react

        let canceled = false;

        const connection = new HubConnectionBuilder()
            .withUrl(hubUrl)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        connection
            .start()
            .then(() => {
                if (!canceled) {
                    console.log('Hub connected');
                    setHubConnection(connection);
                }
            })
            .catch(e => console.log('Hub connection failed: ', e));

        connection.on('ClientConnected', clientId => {
            console.log(`Client connected - ${clientId}`);
            setClientsConnected(c => ({...c, [clientId]: true}));
        });

        connection.on('ClientDisconnected', clientId => {
            console.log(`Client disconnected - ${clientId}`);
            setClientsConnected(c => ({...c, [clientId]: false}));
        });

        connection.onclose(() => {
            if (canceled) {
                return;
            }

            console.log('Hub connection closed');
            setHubConnection(undefined);
        });

        connection.onreconnecting(() => {
            if (canceled) {
                return;
            }

            console.log('Hub reconnecting');
            setHubConnection(undefined);
        });

        connection.onreconnected(() => {
            if (canceled) {
                return;
            }

            console.log('Hub reconnected');
            setHubConnection(connection);
        })

        return () => {
            canceled = true;
            connection.stop();
        }
    }, []);

    const handleDelete = async (certId) => {
        try {
            const response = await fetch(`${apiUrl}/certs/${certId}`, { method: 'DELETE', credentials: 'same-origin' });
            if (response.ok) {
                props.refreshCerts();
            } else {
                console.error('Failed to delete certificate');
            }
        } catch (error) {
            console.error('Error deleting certificate', error);
        }
    };

    const isConnected = (clientId) => {
        let className = 'unknown-state';
        let text = 'Unknown';

        if (hubConnection) {
            if (clientId in clientsConnected && clientsConnected[clientId] === true) {
                className = 'connected';
                text = 'Connected';
            } else {
                className = 'not-connected';
                text = 'Not Connected';
            }
        }

        return <span className={className}>{text}</span>;
    };

    return (
        <div className="cert-list-container">
            <h4>Bindings</h4>
            <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th>Certificate ID</th>
                        <th>Host</th>
                        <th>Description</th>
                        <th>Actions</th>
                        <th>Connection State</th>
                    </tr>
                </thead>
                <tbody>
                    {certificates.map(cert => (
                        <tr key={cert.certificateId}>
                            <td>{cert.certificateId}</td>
                            <td>{cert.host}</td>
                            <td>{cert.description}</td>
                            <td>
                                <Button size="sm" variant="danger" onClick={() => handleDelete(cert.certificateId)}>
                                    Delete
                                </Button>
                            </td>
                            <td>{isConnected(cert.certificateId)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <p><b>Broker Service:</b> {hubConnection ? <span className='connected'>Connected</span> : <span className='not-connected'>Not connected</span>}</p>
        </div>
    );
}

export default CertList;
