import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from "react-router-dom";
import { apiUrl } from './Api';
import Cookies from 'js-cookie';

const AuthContext = createContext({ isAuthenticated: null });

const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const authCheck = async () => {
            try {
                const response = await fetch(`${apiUrl}/isAuthenticated`, { credentials: 'same-origin'});
                if (response.ok) {
                    // Authenticated/authorised
                    setIsAuthenticated(true);
                } else {
                    if (response.status === 403) {
                        // Not authorised
                        setIsAuthenticated(false);
                    } else {
                        // Not authenticated
                        setIsAuthenticated(null);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch auth state', error);
            }
        };

        authCheck();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }  else {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const logOut = () => {
        Cookies.remove('user');
        window.location = "/.auth/logout?post_logout_redirect_uri=/";
    };

    return <AuthContext.Provider value={{ isAuthenticated, logOut }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};