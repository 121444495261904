import { MsLoginLight } from "../../assets/icons";
import './Login.scss';
import { ReservaLogo } from "../../assets/logo";
import { useAuth } from "../Auth";

const Login = () => {
    const auth = useAuth();

    return (
        <div className={"reserva-login-container"}>
            <div className="reserva-login">
                <ReservaLogo />
                <div className="idp-login">
                    <a href="/.auth/login/aad?post_login_redirect_uri=/"><MsLoginLight /></a>
                </div>
                <p className="auth-message" hidden={auth.isAuthenticated !== false} >Not a valid user<br/>Please sign-in with a valid user.</p>
            </div>
        </div>
    );
}

export default Login;