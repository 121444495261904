import React from 'react';
import './Header.scss';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useAuth } from '../Auth';

const Header = () => {
  const auth = useAuth();

  return (
    <header>
          <Navbar expand="lg" className="bg-body-tertiary">
              <Container>
                  <Navbar.Brand href="#">Broker Service Management UI</Navbar.Brand>
                  <button onClick={() => auth.logOut()} className="btn-submit">logout</button>
              </Container>
          </Navbar>
    </header>
  );
};

export default Header;