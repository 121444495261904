import React, { Component } from 'react';
import './CertUpload.scss';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { apiUrl } from '../Api';

export class CertUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host: '',
            certificateFile: null,
            description: '',
            successMessage: '',
        };
    }

    handleHostChange = (event) => {
        this.setState({ host: event.target.value });
    };

    handleFileChange = (event) => {
        this.setState({ certificateFile: event.target.files[0] });
    };

    handleDescriptionChange = (event) => {
        this.setState({ description: event.target.value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { host, certificateFile, description } = this.state;
        const formData = new FormData();
        formData.append('host', host);
        formData.append('certFile', certificateFile);
        formData.append('description', description);

        try {
            const response = await fetch(`${apiUrl}/certs`, {
                method: 'POST',
                body: formData,
                credentials: 'same-origin'
            });

            if (response.ok) {
                this.setState({ successMessage: 'Certificate uploaded successfully!' });
                setTimeout(() => {
                    this.setState({ successMessage: '' });
                }, 1000);

                this.props.refreshCerts(); // Refresh the certificate list
            } else {
                console.error('Failed to upload certificate');
            }
        } catch (error) {
            console.error('Error uploading certificate', error);
        }
    };

    render() {
        const { successMessage } = this.state;

        return (
            <div className="cert-upload-container">
                <h4>Upload Certificate</h4>
                <Form onSubmit={this.handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="7" controlId="formHost">
                            <Form.Label>Host</Form.Label>
                            <Form.Control type="text" placeholder="Enter host" onChange={this.handleHostChange} required />
                            <Form.Text className="text-muted">
                                This host will be bound to the uploaded certificate.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="certificateFile">
                            <Form.Label>Certificate</Form.Label>
                            <Form.Control type="file" onChange={this.handleFileChange} required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="formDescription">
                            <Form.Label>Description (optional)</Form.Label>
                            <Form.Control type="text" placeholder="Enter description" maxLength={256} onChange={this.handleDescriptionChange} />
                        </Form.Group>
                    </Row>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
            </div>
        );
    }
}
