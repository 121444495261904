import React, { useState, useCallback, useEffect } from 'react';
import { CertUpload } from '../CertUpload/CertUpload';
import CertList from '../CertList/CertList';
import './Home.scss';
import { apiUrl } from '../Api';

const Home = () => {
    const [certificates, setCertificates] = useState([]);

    const fetchCerts = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/certs`, { credentials: 'same-origin'});
            if (response.ok) {
                const data = await response.json();
                setCertificates(data);
            }
        } catch (error) {
            console.error('Failed to fetch certificates', error);
        }
    }, []);

    useEffect(() => {
        fetchCerts();
    }, [fetchCerts]);

    return (
        <div className="page-container">
            <div className="content-container">
                <CertUpload refreshCerts={fetchCerts} />
                <hr />
                <CertList certificates={certificates} refreshCerts={fetchCerts} />
            </div>
        </div>
    );
};

export default Home;
